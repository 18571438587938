import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Header from "../../components/layout/Header";
import {  MdProductionQuantityLimits } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import SearchInput from "../../components/reusable/CustomSearch";
import { FaRegEdit } from "react-icons/fa";
import Loader from "../../components/reusable/Loader";
import { clearOrderErrors, getAllOrder } from "../../redux/actions/orderAction";

const OrderList = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const [filteredData, setFilteredData] = useState(null);

  // Pagination state
  const [pageSize, setPageSize] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const { loading, error, orderList } = useSelector(
    (state) => state.getAllOrder
  );

  useEffect(() => {
    if (error) {
      dispatch(clearOrderErrors());
    }
    if (orderList?.products) {
      setFilteredData(orderList?.products);
    }
  }, [error, orderList, dispatch]);

  const handleSearch = () => {
    const filtered = orderList?.products?.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {

    dispatch(getAllOrder());

  }, [dispatch]);

  const columns = [

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Stock Quantity",
      dataIndex: "stockQuantity",
      key: "stockQuantity",
    },

    {
      title: "Price",
      dataIndex: "originalPrice",
      key: "originalPrice",
    },

    {
      title: "Discount Price",
      dataIndex: "discountedPrice",
      key: "discountedPrice",
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },

    {
      title: "Available Size",
      dataIndex: "availableSize",
      key: "availableSize",
      render: (text) => <span>{text?.join(",")}</span>,
    },

    {
      title: "COD",
      dataIndex: "isCodAvailable",
      key: "isCodAvailable",
      render: (text) => <span>{text ? "Yes" : "No"}</span>,
    },

    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString(),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span className="action_btn">
          <button
            className="primary"
            onClick={() => navigate(`/admin/category-edit/${record._id}`)}
            style={{ marginRight: 8 }}
          >
            <FaRegEdit />
          </button>
        </span>
      ),
    },

  ];

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <Header title="Order History" icon={<MdProductionQuantityLimits />} />
          <section className="product_list">
            <div className="create_btn">
              <SearchInput
                onSearch={handleSearch}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
            <div className="table_list">
              <Table
                columns={columns}
                dataSource={filteredData}
                rowKey="_id"
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: filteredData?.length,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  onChange: handlePageChange,
                  onShowSizeChange: handlePageSizeChange,
                }}
              />
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default OrderList;
