import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Header from "../../components/layout/Header";
import { MdProductionQuantityLimits } from "react-icons/md";
import CustomInput from "../../components/reusable/CustomInput";
import CustomMultiSelect from "../../components/reusable/CustomMultiSelect";
import "../../styles/productCreate.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { toast } from "react-toastify";
import {
  clearProductErrors,
  getProductByIdAction,
  updateProduct,
} from "../../redux/actions/productAction";
import {
  clearCATEGORYErrors,
  getAllCATEGORY,
} from "../../redux/actions/categoryAction";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Loader from "../../components/reusable/Loader";
const ProductEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    loading: detailLoading,
    error: detailError,
    productDetail,
  } = useSelector((state) => state.getProductById);

  useEffect(() => {
    if (id) {
      dispatch(getProductByIdAction(id));
    }
  }, [id, dispatch]);

  const details = productDetail?.product;

  useEffect(() => {
    if (detailError) {
      dispatch(clearProductErrors());
    }
  }, [dispatch, detailError]);

  const [categoryId, setCategoryId] = useState(details?.category?._id);

  const [availableSize, setAvailableSize] = useState(details?.availableSize);

  const [images, setImages] = useState(details?.images);

  const [imagePreview, setImagePreview] = useState();

  const [productData, setProductData] = useState({
    title: details?.title,
    description: details?.description,
    stockQuantity: details?.stockQuantity,
    originalPrice: details?.originalPrice,
    discountedPrice: details?.discountedPrice,
    isTaxIncluded: true,
    isCodAvailable: true,
  });

  useEffect(() => {
    if (details) {
      let sizes = [];
      details?.availableSize?.forEach((ele) => {
        sizes.push({
          label: ele,
          value: ele,
        });
      });

      setAvailableSize(sizes);
      setCategoryId({
        label: details?.category?.title,
        value: details?.category?._id,
      });
      setImages(details?.images);

      setProductData({
        title: details?.title,
        description: details?.description,
        stockQuantity: details?.stockQuantity,
        originalPrice: details?.originalPrice,
        discountedPrice: details?.discountedPrice,
        isTaxIncluded: true,
        isCodAvailable: true,
      });
    }
  }, [details]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    if (e.target.name === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader?.readyState === 2) {
          setImagePreview(reader?.result);
        }
      };
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const handleAddImage = () => {
    setImages((prev) => [...prev, imagePreview]);
    setImagePreview();
  };

  const handleRemoveImage = (id) => {
    const filter = images?.filter((_, idx) => idx !== id);
    setImages(filter);
  };

  const {
    loading: categoryLoading,
    error: categoryError,
    category,
  } = useSelector((state) => state.getCategoryList);

  let categoryList = [];

  category?.categories?.forEach((ele) => {
    categoryList.push({
      label: ele?.title,
      value: ele?._id,
    });
  });

  useEffect(() => {
    dispatch(getAllCATEGORY());
  }, [dispatch]);

  useEffect(() => {
    if (categoryError) {
      toast.error(categoryError);
      dispatch(clearCATEGORYErrors());
    }
  }, [categoryError, dispatch]);

  const { loading, error, updateData } = useSelector(
    (state) => state.updateProduct
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearProductErrors());
    }
    if (updateData?.success) {
      toast.success(updateData?.message);
    }
  }, [error, dispatch, updateData?.success, updateData?.message]);

  const {
    title,
    description,
    originalPrice,
    discountedPrice,
    stockQuantity,
    isTaxIncluded,
    isCodAvailable,
  } = productData;

  const handleSubmit = () => {
    const updatedPrice = parseInt(originalPrice);
    const updatesDiscount = parseInt(discountedPrice);
    const sizes = availableSize?.map((ele) => ele.label);
    dispatch(
      updateProduct(
        id,
        title,
        description,
        updatedPrice,
        updatesDiscount,
        stockQuantity,
        isTaxIncluded,
        isCodAvailable,
        categoryId?.value,
        sizes,
        images
      )
    );
  };

  return (
    <Fragment>
      {detailLoading ? (
        <Loader />
      ) : (
        <Layout>
          <Header
            title="Product Update"
            icon={<MdProductionQuantityLimits />}
          />

          <div className="go_back">
            <button onClick={() => navigate(-1)}>
              <FaArrowLeftLong />
            </button>
          </div>
          <section className="product_create">
            <div className="input_grid">
              <CustomInput
                label="Product Name"
                placeholder="ex: a2 desi cow ghee"
                name="title"
                type="text"
                impStar="*"
                value={details ? details?.title : productData?.title}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Product Price"
                placeholder="Enter product orignal price"
                name="originalPrice"
                type="number"
                impStar="*"
                value={
                  details ? details?.originalPrice : productData.originalPrice
                }
                onChange={handleInputChange}
              />
              <CustomInput
                label="Discount price"
                placeholder="Enter discount price"
                name="discountedPrice"
                type="number"
                onChange={handleInputChange}
                value={
                  details
                    ? details?.discountedPrice
                    : productData.discountedPrice
                }
                impStar="*"
              />
              <CustomInput
                label="Stock Quantity"
                placeholder="Enter total available items in stock"
                name="stockQuantity"
                type="number"
                impStar="*"
                value={
                  details ? details?.stockQuantity : productData.stockQuantity
                }
                onChange={handleInputChange}
              />

              <CustomMultiSelect
                label="Select product category"
                name="category"
                borderColor="#124b04"
                isMulti={false}
                optionArray={categoryList}
                impStar="*"
                value={categoryId}
                onChange={(val) => setCategoryId(val)}
              />
              <CustomMultiSelect
                label="Choose available size"
                borderColor="#124b04"
                name="availableSize"
                value={availableSize}
                loading={categoryLoading}
                onChange={(val) => setAvailableSize(val)}
                isMulti={true}
                optionArray={[
                  {
                    label: "250gm",
                    value: 250,
                  },
                  {
                    label: "500gm",
                    value: 500,
                  },
                  {
                    label: "1kg",
                    value: 1,
                  },
                  {
                    label: "5kg",
                    value: 5,
                  },
                ]}
                impStar="*"
              />
              <CustomTextarea
                label="Product Description"
                placeholder="Write about product"
                name="description"
                type="text"
                impStar="*"
                onChange={handleInputChange}
                value={
                  details ? details?.description : productData?.description
                }
              />
              <div className="image_upload">
                <label>
                  <span>Upload Images </span> <br />
                  (Max 4 & Main Front images should be select on first)
                </label>
                <div className="selectImage">
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  {imagePreview !== "" && imagePreview !== undefined && (
                    <div>
                      <img src={imagePreview} alt="preview-image" />
                      <button onClick={handleAddImage}>
                        <FaPlus />
                      </button>
                    </div>
                  )}
                </div>
                {images?.length > 0 && <p>Preview List :</p>}
                {images?.length > 0 && (
                  <div className="image_preview">
                    {images.map((preview, index) => (
                      <div key={index}>
                        <img
                          src={
                            "https://dev-api.saajfarms.in/uploads/" + preview
                          }
                          alt={`preview-${index}`}
                        />
                        <button onClick={() => handleRemoveImage(index)}>
                          <RxCross2 />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="submit_btn">
              <CustomButton
                title={loading ? <ButtonLoader /> : "Update"}
                width="90px"
                onClick={handleSubmit}
              />
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default ProductEdit;
