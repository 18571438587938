import {
  CLEAR_DELETE_PRODUCT_STATE,
  CLEAR_PRODUCT_ERROR,
  CLEAR_PRODUCT_STATE,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_FAIL,
  GET_ALL_PRODUCT_REQUEST,
  GET_ALL_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_DETAIL_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
} from "../constant/ProductConstant";

// CREATE NEW BATCH BY ADMIN
export const createProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return {
        loading: true,
        productCreate: {},
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        productCreate: action.payload,
      };
    case CREATE_PRODUCT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PRODUCT_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PRODUCT_STATE:
      return {
        loading: false,
        error: null,
        productCreate: {},
      };

    default:
      return state;
  }
};

// GET ALL products
export const getAllProductReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT_REQUEST:
      return {
        loading: true,
        products: {},
      };
    case GET_ALL_PRODUCT_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case GET_ALL_PRODUCT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PRODUCT_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PRODUCT_STATE:
      return {
        loading: false,
        error: null,
        products: {},
      };

    default:
      return state;
  }
};

// GET ALL BATCH BY ID
export const getProductByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAIL_REQUEST:
      return {
        loading: true,
        productDetail: {},
      };
    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        loading: false,
        productDetail: action.payload,
      };
    case GET_PRODUCT_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PRODUCT_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PRODUCT_STATE:
      return {
        loading: false,
        error: null,
        productDetail: {},
      };

    default:
      return state;
  }
};

//   UPDATE BLOG REDUCER
export const updateProductReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_REQUEST:
      return {
        loading: true,
        updateData: {},
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        updateData: action.payload,
      };
    case UPDATE_PRODUCT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PRODUCT_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PRODUCT_STATE:
      return {
        loading: false,
        error: null,
        updateData: {},
      };

    default:
      return state;
  }
};

// DELETE BATCH BY ADMIN
export const deleteProductByAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return {
        loading: true,
        productDelete: {},
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        loading: false,
        productDelete: action.payload,
      };
    case DELETE_PRODUCT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PRODUCT_ERROR:
      return {
        loading: false,
        error: null,
      };
    case CLEAR_DELETE_PRODUCT_STATE:
      return {
        loading: false,
        error: null,
        productDelete: {},
      };

    default:
      return state;
  }
};
